// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complaints-examples-js": () => import("./../../../src/pages/complaints/examples.js" /* webpackChunkName: "component---src-pages-complaints-examples-js" */),
  "component---src-pages-complaints-file-upload-js": () => import("./../../../src/pages/complaints/file-upload.js" /* webpackChunkName: "component---src-pages-complaints-file-upload-js" */),
  "component---src-pages-complaints-index-js": () => import("./../../../src/pages/complaints/index.js" /* webpackChunkName: "component---src-pages-complaints-index-js" */),
  "component---src-pages-complaints-thanks-js": () => import("./../../../src/pages/complaints/thanks.js" /* webpackChunkName: "component---src-pages-complaints-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-thebiobreak-blog-index-js": () => import("./../../../src/pages/thebiobreak/blog/index.js" /* webpackChunkName: "component---src-pages-thebiobreak-blog-index-js" */),
  "component---src-pages-thedevbranch-blog-index-js": () => import("./../../../src/pages/thedevbranch/blog/index.js" /* webpackChunkName: "component---src-pages-thedevbranch-blog-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-biobreak-page-js": () => import("./../../../src/templates/biobreak-page.js" /* webpackChunkName: "component---src-templates-biobreak-page-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-devbranch-page-js": () => import("./../../../src/templates/devbranch-page.js" /* webpackChunkName: "component---src-templates-devbranch-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-petzebel-page-js": () => import("./../../../src/templates/petzebel-page.js" /* webpackChunkName: "component---src-templates-petzebel-page-js" */),
  "component---src-templates-petzebel-post-js": () => import("./../../../src/templates/petzebel-post.js" /* webpackChunkName: "component---src-templates-petzebel-post-js" */),
  "component---src-templates-support-page-js": () => import("./../../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

